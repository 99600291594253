import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";

const ThankYou = () => {
  return (
    <div className="max-w-3xl m-auto bg-white p-5">
      <Heading>Awesome, you are subscribed!</Heading>

      <div className="grid gap-3">
        <div>
          We will send you the next issue directly to your inbox as soon as it is ready.
        </div>

        <div>
          You can read the latest issue of our newsletter right
          on our <Link url="https://stockrow.com">front page</Link>.
        </div>

        <div>
          Previous issues can be found in <Link url="https://stockrow.kit.com">the archive</Link>.
        </div>
      </div>
    </div>
  )
}

export default ThankYou;

import "./App.css";

import AccountSettings from "AccountSettings";
import AnalystCommentary from "./AnalystCommentary";
import BlankRoot from "layouts/BlankRoot";
import BusinessProfile from "./BusinessProfile";
import ContactUs from "./ContactUs";
import CreateAccount from "./CreateAccount";
import FinancialsTable from "./FinancialsTable";
import ForgottenPassword from "./ForgottenPassword";
import Homepage from "./Homepage";
import KeystatsReport from "./KeystatsReport";
import Error from "./Error";
import Login from "./Login";
import MainTable from "FinancialsTable/MainTable";
import React from "react";
import Root from "./layouts/Root";
import RootWithPattern from "./layouts/RootWithPattern";
import Screener from "./Screener";
import Subscribed from "./Subscribed";
import ThankYou from "ThankYou";
import Terms from "Terms";

import Indicators from "Indicators";
import Wiki from "Wiki";

import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/indicators/",
    element: <RootWithPattern />,
    children: [
      {
        index: true,
        element: <Indicators />
      }
    ]
  },
  {
    path: "/wiki/:slug",
    element: <RootWithPattern />,
    children: [
      {
        index: true,
        element: <Wiki />
      }
    ]
  },
  {
    path: "/terms",
    element: <RootWithPattern />,
    children: [
      {
        index: true,
        element: <Terms />
      }
    ]
  },
  {
    path: "/thank-you",
    element: <RootWithPattern />,
    children: [
      {
        index: true,
        element: <ThankYou />
      }
    ]
  },
  {
    path: "/contact",
    element: <RootWithPattern />,
    children: [
      {
        index: true,
        element: <ContactUs />
      }
    ]
  },
  {
    path: "/account",
    element: <RootWithPattern />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "create",
        element: <CreateAccount />,
      },
      {
        path: "forgotten_password",
        element: <ForgottenPassword />,
      },
    ]
  },
  {
    path: "subscribed",
    element: <BlankRoot />,
    children: [
      {
        path: ":sessionId",
        element: <Subscribed />
      }
    ]
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/account_settings",
        element: <AccountSettings />,
      },
      {
        path: "/screener",
        element: <Screener />,
      },
      {
        path: "/screener/:screenerToken",
        element: <Screener />,
      },
      {
        path: ":descriptorShortcode",
        element: <KeystatsReport />,
      },
      {
        path: ":descriptorShortcode/business-profile",
        element: <BusinessProfile />,
      },
      {
        path: ":descriptorShortcode/analyst-commentary",
        element: <AnalystCommentary />,
      },
      {
        path: ":descriptorShortcode/:fincialsTableCode",
        element: <FinancialsTable />,
        children: [
          {
            path: ":financialsTableParams",
            element: <MainTable />,
          }
        ]
      }
    ]
  }
]);

const App = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;

import React from "react";
import Heading from "common/Heading";
import LoadingBox from "common/LoadingBox";
import { useQuery } from '@apollo/client';
import { NEWS_DAILY_SUMMARY_QUERY } from "queries"
import { Helmet } from "react-helmet";

const NewsSummary = () => {
  const {
    loading,
    data: {
      newsSummary: {
        isoSummaryDate,
        summaryDate,
        text,
      } ={}
    } ={}
  } = useQuery(NEWS_DAILY_SUMMARY_QUERY);

  if (loading) { return <LoadingBox /> }

  let marketSummary = {
    "@context": "http://schema.org",
    "@type": "Article",
    "name": `Daily Market Summary – ${summaryDate}`,
    "datePublished": isoSummaryDate,
    "articleBody": text,
    "author": [
      {
        "@type": "Organization",
        "name": "stockrow.com",
        "url": "https://stockrow.com"
      }
    ]
  }

  const articleClass = `
    [&_a]:underline
    [&_a]:text-sky-700
    [&_a:hover]:text-red-700
    [&_h2]:text-xl
    [&_h2]:font-mono
    [&_h2]:pb-2
    [&_p]:pb-5
    [&_p]:lg:pb-9
    flex
    flex-col
    text-md
    lg:text-lg
  `;

  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {`
            ${JSON.stringify(marketSummary)}
          `}
        </script>
      </Helmet>

      <div
        itemScope
        itemType="http://schema.org/Article"
      >
        <div
          className="px-3 lg:px-10 pt-5 pb-2"
          itemProp="name headline"
        >
          <Heading>{`Daily Market Summary – ${summaryDate}`}</Heading>
        </div>

        <span
          className="hidden"
          itemProp="datePublished"
        >
          {isoSummaryDate}
        </span>

        <span
          className="hidden"
          itemProp="publisher"
          itemScope
          itemType="http://schema.org/Organization"
        >
          <span itemProp="name">
            stockrow.com
          </span>
        </span>

        <span
          itemProp="url"
          className="hidden"
        >
          https://stockrow.com
        </span>

        <div className="w-full m-auto bg-amber-50 p-3 lg:p-10">
          <div className="2xl:w-1/2 xl:w-4/6 m-auto">
            <div
              className={articleClass}
              dangerouslySetInnerHTML={{ __html: text }}
              itemProp="articleBody"
            />

            <div className="text-slate-400 font-mono text-xs mt-10">
              Summaries are generated daily at 7pm New York time
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsSummary;
